<script>
import { mapActions, mapGetters } from 'vuex'
import managementMixin from '@/modules/Management/managementMixin'
export default {
  name: 'ManagementSurvey',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Action: () => import('@/components/general/Action'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    Tabs: () => import('@/components/general/Tabs'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  mixins: [ managementMixin ],

  data () {
    return {
      surveyList: null,
      pagination: {
        query: {},
        filter: {},
        order: {
          active: 'desc',
          name: 'asc'
        },

        page: 1,
        limit: 12,
        lastPage: 3
      },

      search: null,
      modalRemoveOpen: false,
      sessionId: null,
      totalLinkedCountModal: null
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),
    canReadSurveys () {
      return [
        'read',
        'write'
      ].indexOf(this.getContextPermission('surveys')) !== -1
    },

    canWrite () {
      return this.getContextPermission('surveys') === 'write'
    },

    modalDescription () {
      if (this.totalLinkedCountModal > 0) {
        if (this.isProgramEnabled) {
          return 'Tem certeza que deseja inativar essa pesquisa? Todos os ' + this.totalLinkedCountModal + ' vínculos serão removidos das soluções, trilhas e programas que possuírem essa pesquisa'
        } else {
          return 'Tem certeza que deseja inativar essa pesquisa? Todos os ' + this.totalLinkedCountModal + ' vínculos serão removidos das soluções e trilhas que possuírem essa pesquisa'
        }
      } else {
        return 'Tem certeza que deseja inativar essa pesquisa?'
      }
    },

    isProgramEnabled () {
      return this.activeModules.includes('program')
    }
  },

  watch: {
    'pagination.page' () {
      this.fetchData()
    }
  },

  created () {
    if (this.$route.name !== 'management.survey') {
      return
    }

    this.fetchData()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptCreateSurvey',
      'attemptGetSurvey',
      'attemptGetSurveyBySlug',
      'attemptListSurveys',
      'attemptToggleSurvey'
    ]),

    createNewSurvey () {
      this.$router.push({ name: 'management.survey.edit' })
    },

    // treatQuestions (questions) {
    //   return questions.map((item, index) => {
    //     if (item.data) {
    //       item.data.min = parseInt(item.data.min)
    //       item.data.max = parseInt(item.data.max)
    //     }

    //     item.title = item.name
    //     item.ordering = index
    //     item.required = item.required ? 1 : 0

    //     item.options = item.surveyQuestionOption.map((answer, answerIndex) => {
    //       answer.ordering = answerIndex
    //       answer.value = answer.name.replace(/ +/g, '-')

    //       return answer
    //     })

    //     return JSON.stringify(item)
    //   })
    // },

    // clone (id) {
    //   this.setFetching(true)

    //   this.attemptGetSurvey(id).then((survey) => {
    //     const cloneData = {
    //       id: null,
    //       name: survey.data.name,
    //       description: survey.data.description,
    //       finishText: survey.data.finishText,
    //       slug: null,
    //       type: survey.data.type,
    //       visibilityEnabled: survey.data.visibility,
    //       fixedQuestions: survey.data.type === 'satisfaction',
    //       surveyQuestion: this.treatQuestions(survey.data.surveyQuestion)
    //     }

    //     this.attemptCreateSurvey(cloneData).then(() => {
    //       this.setFeedback({ message: this.$t('management.survey:feedback.cloned.success') })
    //       this.fetchData()
    //     }).catch(() => {
    //       this.setFeedback({ message: this.$t('management.survey:feedback.cloned.error') })
    //     }).finally(() => {
    //       this.setFetching(false)
    //     })
    //   }).catch(() => {
    //     this.setFeedback({ message: this.$t('management.survey:feedback.cloned.error') })
    //   }).finally(() => {
    //     this.setFetching(false)
    //   })
    // },

    edit (id) {
      this.$router.push({
        name: 'management.survey.edit',
        params: { id: id }
      })
    },

    toggle () {
      this.setFetching(true)

      this.attemptToggleSurvey(this.sessionId).then(({ data }) => {
        if (data.active) {
          this.setFeedback({ message: this.$t('management.survey:feedback.activated.success') })
        } else {
          this.setFeedback({ message: this.$t('management.survey:feedback.deactivated.success') })
        }

        this.modalRemoveOpen = false

        this.fetchData()
      }).catch((error) => {
        // TODO: arrumar isso para contemplar as duas situações
        if (error.data.error.message === 'subscription_empty_survey_question') {
          this.setFeedback({ message: this.$t('management.survey:subscription.empty.survey.question') })
        } else {
          this.setFeedback({ message: this.$t('management.survey:feedback.deactivated.error') })
        }
      }).finally(() => {
        this.setFetching(false)
      })
    },

    searchSurvey (search) {
      this.search = search

      this.pagination.query = {
        name: search
      }

      this.pagination.page = 1
      this.fetchData()
    },

    dateFormat (datetime) {
      if (datetime === '' || datetime === undefined || datetime === null) {
        return datetime
      }

      const date = new Date(Date.parse(datetime))

      return Intl.DateTimeFormat('pt-BR').format(date)
    },

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    fetchData () {
      this.setFetching(true)

      this.attemptListSurveys(this.pagination)
        .then(({ data }) => {
          this.surveyList = data.data || []
          this.pagination.page = data.actualPage
          this.pagination.lastPage = data.lastPage
        })
        .catch(() => {
          this.surveyList = []
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    viewSurveySolutions (id) {
      this.$router.push({
        name: 'management.survey.list.solutions',
        params: { id }
      })
    },

    openPage (slug) {
      this.attemptGetSurveyBySlug(slug).then(({ data }) => {
        window.open(`${process.env.VUE_APP_PORTAL_URL}pesquisa/${data.slug}`, '_blank')
      }).finally(() => {
        this.setFetching(false)
      })
    },

    totalLinkedCount (item) {
      if (item.type === 'external') {
        return '-'
      }

      return item.totalLinked
    },

    confirmRemove (item) {
      this.sessionId = item.id

      if (item.active) {
        this.totalLinkedCountModal = this.totalLinkedCount(item)
        this.modalRemoveOpen = true

        return
      }

      this.toggle()
    },

    cancelRemove () {
      this.modalRemoveOpen = false
    },

    canOpenPage (item) {
      return item.type === 'external' && item.active === true
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('management:header.title')"
      :description="$t('management:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>
    <FilterList>
      <Action
        v-if="canWrite"
        slot="button"
        type="button"
        primary
        large
        fixed-width
        :text="$t('management.survey:btn.add')"
        @click="createNewSurvey"
      />
      <!--
 TODO: FilterOrder
-->
      <FilterListSearch
        slot="search"
        hide-error-details
        @search="searchSurvey"
      />
    </FilterList>
    <div class="center management-content">
      <Datatable
        v-if="surveyList && surveyList.length > 0"
        :items="surveyList"
      >
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('management.survey.list:datatable.header.1') }}
            </th>
            <th class="th">
              {{ $t('management.survey.list:datatable.header.2') }}
            </th>
            <th class="th">
              {{ $t('management.survey.list:datatable.header.3') }}
            </th>
            <th class="th">
              {{ $t('management.survey.list:datatable.header.4') }}
            </th>
            <th class="th">
              {{ $t('management.survey.list:datatable.header.5') }}
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.name }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ $t('management.survey.type:' + props.item.type) }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ dateFormat(props.item.createdAt) }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ totalLinkedCount(props.item) }}</span>
            </td>
            <td class="td">
              <span
                v-if="props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('management.list:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('management.list:inactive') }}</span>
            </td>
            <td
              v-if="canWrite || canReadSurveys"
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  v-if="props.item.totalLinked > 0 && canReadSurveys"
                  :text="$t('management.survey:solutions.list.view')"
                  @click="viewSurveySolutions(props.item.id)"
                />
                <DropdownLink
                  v-if="!props.item.surveyAnswered && canWrite"
                  :text="$t('global:edit')"
                  @click="edit(props.item.id)"
                />
                <!--
                <DropdownLink
-->
                <!--
                  v-if="canWrite"
-->
                <!--
                  :text="$t('global:clone')"
-->
                <!--
                  @click="clone(props.item.id)"
-->
                <!--
                />
-->
                <DropdownLink
                  v-if="canOpenPage(props.item)"
                  :text="$t('global:show.page')"
                  @click="openPage(props.item.slug)"
                />
                <DropdownLink
                  v-if="!props.item.canceled && canWrite"
                  :text="props.item.active ? $t('global:deactivate') : $t('global:activate')"
                  @click="confirmRemove(props.item)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
      <RouterView />
    </div>
    <ModalConfirm
      :active="modalRemoveOpen"
      :title="$t('management.survey:deactivate.title')"
      :description="$t(modalDescription)"
      @confirmAction="toggle"
      @cancelAction="cancelRemove"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>

<style>
.management-content .td:first-child {
  max-width: 280px;
  padding-right: 20px;
}
</style>
